@import "reboot";


//@media (min-width: 768px) {
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

//}
.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

#header {
  background: #0061df;
  color: #fff;
  padding: .5rem 1rem;
  border-radius: 3px;
  display: flex;

  a, a:hover, a:active, a:focus {
    display: block;
    line-height: 2.5rem;
    height: 2.75rem;
    background: url(../images/logo.svg) no-repeat 0 50%;
    background-size: contain;
    padding-left: 3.75rem;
    font-size: 1.75rem;
    font-weight: 500;
  }

  .ab-total {
    font-size: .875rem;
    line-height: 1;
    text-align: right;

    .ab-stars {
      margin-bottom: .25rem;

      .ab-star {
        width: 1.25rem;
        height: 1.25rem;

        &.ab-star-off {
          background-image: url(../images/star-w.svg);
          opacity: .333;
        }
      }
    }

    em {
      font-style: normal;
      color: rgba(#fff, .7)
    }
  }
}

.ab-testimonial {
  border-radius: 3px;
  border: 1px solid rgba(73, 80, 87, .1);
  margin-top: 1rem;
  padding: 1rem;

  .ab-meta {
    display: flex;
    flex-wrap: wrap;
  }

  .ab-user {
    display: flex;
    margin-bottom: 1rem;
  }

  .ab-info {
    margin-left: 1rem;

    p {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1;
    }

    a {
      color: #6c757d;
      font-size: .875em;
    }
  }

  .ab-avatar {
    flex-shrink: 0;

    img {
      mask-image: url(../images/mask.svg);
      mask-size: contain;
      border-radius: 0.75rem;
      height: 43px;
      width: 43px;
    }
  }

  .ab-info {
    flex-shrink: 1;
  }

  .ab-comment,
  .ab-text {
    font-size: 1.125rem;
    line-height: 1.6em;

    p {
      margin: 0 0 1rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .ab-rating {
    text-align: right;
    padding-top: .25rem;

    .ab-star {
      width: 1rem;
      height: 1rem;
    }
  }

  .ab-comment {
    background: #F8F9FA;
    color: #6c757d;
    border-radius: 3px;
    padding: 1rem;
    margin: 1rem 0 0 3rem;

    .ab-user {
      margin: 0;
    }

    .ab-info {
      p {
        font-size: 1rem;
        line-height: 1.4;
        margin: 0;
      }
    }
  }
}

#footer {
  padding-top: 1.5rem;
  text-align: center;

  .ab-write {
    text-transform: uppercase;
    background-color: #0061df;
    border: 1px solid #0061df;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.642;
    padding: 0.5em 2em;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    margin-bottom: .75rem;

    &:hover {
      background-color: #0052be;
      border-color: #004eb2;
    }
  }

  .ab-more {
    color: #0061df;
    text-decoration: underline;

    &:hover {
      color: #004eb2;
      text-decoration: underline;
    }
  }
}

.ab-star {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: transparent no-repeat 50%;
  background-size: contain;

  &.ab-star-on {
    background-image: url(../images/star-on.svg);
  }

  &.ab-star-off {
    background-image: url(../images/star-off.svg);
  }
}

.message {
  padding: 1rem;
  border-radius: 3px;
  background: #f6d9d9;
  color: #a64242;
  margin-top: 1rem;
}
