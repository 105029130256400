*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Jost",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.642;
  color: #303a3e;
  text-align: left;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

button {
  margin: 0; // 1
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
  text-transform: none;
  -webkit-appearance: button; // 2
  cursor: pointer; // 3
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
